<template>
  <div class="sm:grid sm:grid-cols-4">
    <div class="col-start-2 col-span-2">
      <font-awesome-icon
        icon="fa-solid fa-lock"
        class="bg-primary text-white w-12 h-12 mx-auto flex rounded-full p-8 mt-4"
      />

      <div class="mt-4 font-bold text-lg text-center">Change Password</div>
      <ValidationObserver v-slot="{ invalid }" class="block mt-8">
        <ValidationProvider name="current password" :rules="{ required: true }">
          <div class="input-field-label">Current Password</div>
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.currentPassword"
              :type="showPassword.current ? 'text' : 'password'"
              placeholder="Current Password"
              class="input-field"
              autocomplete="new-password"
            />
            <div
              v-on:click="showPassword.current = !showPassword.current"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showPassword.current"
                icon="fa-solid fa-eye"
              />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          class="block mt-6"
          name="new password"
          rules="required|confirmed:confirmation"
        >
          <div class="input-field-label">New Password</div>
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.newPassword"
              :type="showPassword.new ? 'text' : 'password'"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              placeholder="New Password"
              class="input-field"
              autocomplete="new-password"
            />
            <div
              v-on:click="showPassword.new = !showPassword.new"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showPassword.new"
                icon="fa-solid fa-eye"
              />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          class="block mt-6"
          name="password confirmation"
          rules="required"
          vid="confirmation"
        >
          <div class="input-field-label">Confirm New Password</div>
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.confirmationPassword"
              :type="showPassword.confirmation ? 'text' : 'password'"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              placeholder="Confirm New Password"
              class="input-field"
              autocomplete="new-password"
            />
            <div
              v-on:click="
                showPassword.confirmation = !showPassword.confirmation
              "
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showPassword.confirmation"
                icon="fa-solid fa-eye"
              />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
        </ValidationProvider>
        <span class="text-xs mt-4">
          <font-awesome-icon
            icon="fas fa-info-circle"
            class="text-primary text-xs"
          />
          Your password must contain at least 1 lowercase, 1 uppercase, 1
          number, and 1 special character.
        </span>
        <div class="flex justify-between mt-8">
          <button @click="resetForm" class="button-cancel">Cancel</button>
          <button @click="requestOtp" :disabled="invalid" class="button-submit">
            Update
          </button>
        </div>
      </ValidationObserver>
    </div>
    <modal-otp ref="modalOTPRef"></modal-otp>
  </div>
</template>

<script>
export default {
  name: "AccountPassword",
  components: {
    ModalOtp: () => import("@/components/layout/ModalOtp.vue"),
  },
  data() {
    return {
      form: {
        currentPassword: null,
        newPassword: null,
        confirmationPassword: null,
      },
      showPassword: {
        current: false,
        new: false,
        confirmation: false,
      },
      otp: "",
      otpTimer: 0,
    };
  },

  computed: {
  },

  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Account", routeName: "AccountProfile" },
      { text: "Account Setting", routeName: "AccountProfile" },
      { text: "Password", routeName: "AccountPassword" },
    ]);
  },

  methods: {
    resetForm() {
      this.form = {
        currentPassword: null,
        newPassword: null,
        confirmationPassword: null,
      };
    },
    async requestOtp() {
      this.$store.commit("setOverlayLoading", true);

      const checkData = new FormData();
      checkData.append("txtTelepon", this.formattedPhone);
      
      var diff = 0;
      let otpResponse;
      try {
        otpResponse = await this.$http.post(
          `${this.$apiTripweWebV2}/users/update-password/validate`,
          {
            old_password: this.form.currentPassword,
            new_password: this.form.newPassword,
            confirm_new_password: this.form.confirmationPassword,
          }
        );

        if (otpResponse.data.status != 200 && otpResponse.data.status != 403) {
          throw otpResponse.data.message;
        }
        if(otpResponse.data.status == 403){
          this.$toasted.global.error(otpResponse.data.message);
        }else{
          this.$toasted.global.success(otpResponse.data.message);
        }
        var curTime = this.$moment();
        var nextReq = this.$moment(otpResponse.data.data.next_request_at);
        diff = nextReq.diff(curTime, 'seconds');
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      
      if (otpResponse.data.status == 200 || otpResponse.data.status == 403) {
        const otp = await this.$refs.modalOTPRef.open({ button: "Submit", scope: "UPDATE_PASSWORD", otpTimer: diff });
        if (otp != null) {
          this.otp = otp;
          this.updatePassword();
        }
      }

    },
    async updatePassword() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.post(
          `${this.$apiTripweWebV2}/users/update-password`,
          {
            old_password: this.form.currentPassword,
            new_password: this.form.newPassword,
            confirm_new_password: this.form.confirmationPassword,
            otp: this.otp,
          }
        );

        if (response.data.status != "200") {
          throw response.data.message;
        }

        this.$toasted.global.success("Your password has been updated");
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.resetForm();

      this.$store.commit("setOverlayLoading", false);
    },

    setOtpTimer() {
      this.otpTimer = 300;
      this.startOtpTimerCountdown();
    },

    startOtpTimerCountdown() {
      if (this.otpTimer > 0) {
        setTimeout(() => {
          this.otpTimer -= 1;
          this.startOtpTimerCountdown();
        }, 1000);
      }
    },
  },
};
</script>
